<template>
  <div>
    <section class="goal">
      <h2>ローカルストレージの操作</h2>
      <div class="wrap">
        <input :value="text" @change="onChangeText" />
        <button @click="clearStorage">ローカルストレージをクリア</button>
        <pre>{{ text }}</pre>
      </div>
    </section>
    <hr class="border" />
    <p class="border_txt">これ以下は模写不要</p>
    <section class="question">
      <h2>問題</h2>
      <div class="wrap">
        <p class="question_txt">
          以上のアプリケーションを作成しなさい<br />
          <br />
          【注意】<br />
          リロードしてもデータがクリアされない点に注意しなさい
        </p>
      </div>
    </section>
    <section class="answer">
      <h2>回答</h2>
      <div v-if="showAnswer">
        <button class="answer_btn" @click="showAnswer = false">
          回答を非表示
        </button>
        <div class="wrap">
          <pre>
            <code v-pre>
&lt;template&gt;
  &lt;div&gt;
    &lt;input :value="text" @change="onChangeText" /&gt;
    &lt;button @click="clearStorage"&gt;ローカルストレージをクリア&lt;/button&gt;
    &lt;pre&gt;{{ text }}&lt;/pre&gt;
  &lt;/div&gt;
&lt;/template&gt;
&lt;script&gt;
export default {
  data() {
    return {
      text: localStorage.getItem("text") ?? "",
    };
  },
  methods: {
    onChangeText(e) {
      this.text = e.target.value;
      localStorage.setItem("text", e.target.value);
    },
    clearStorage() {
      localStorage.removeItem("text");
    },
  },
};
&lt;/script&gt;
            </code>
          </pre>
        </div>
      </div>
      <button v-else class="answer_btn" @click="showAnswer = true">
        回答を表示
      </button>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showAnswer: false,
      text: localStorage.getItem("text") ?? "",
    };
  },
  methods:{
    onChangeText(e) {
      this.text = e.target.value;
      localStorage.setItem("text", e.target.value);
    },
    clearStorage() {
      localStorage.removeItem("text");
    },
  }
};
</script>
